import React, { useCallback, useEffect, useState, useMemo } from 'react'
import { z } from 'zod'
import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
	Button,
	CircularProgress,
	Grid,
	Typography,
	TextField,
	Box,
	MenuItem,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	Alert,
} from '@mui/material'
import { useHistory, useParams } from 'react-router-dom'

// project imports
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import axios from 'axios'
import useCertificateDataFetcher, {
	Certificate,
	Course,
	ProficiencyAssessment,
	CertificateAssessment,
} from '../../../hooks/useCertificateDataFetcher'
import MainCard from '../../../ui-component/cards/MainCard'
import LabelTag from '../../../ui-component/label-tag'
import { BASE_URL } from '../../../config'
import useAxiosFetch from '../../../hooks/useFetch'

type FormData = {
	certificateName: string
	certificateCourses: Course[]
	certificateAssessments: CertificateAssessment[]
	minimumMasteryScore: number
	expiryPeriod: number
}

type SnackbarSeverity = 'success' | 'info' | 'warning' | 'error'

const certificateSchema = z.object({
	certificateName: z
		.string()
		.min(1, { message: 'Certificate name is required' }),
	minimumMasteryScore: z
		.union([z.number(), z.string()])
		.transform((val) => {
			const numericVal = typeof val === 'string' ? parseFloat(val) : val
			return Math.round(numericVal * 10) / 10
		})
		.refine((val) => !Number.isNaN(val) && val >= 1 && val <= 5, {
			message: 'Minimum mastery score must be between 1.0 and 5.0',
		}),
	expiryPeriod: z
		.union([z.number(), z.string()])
		.transform((val) => {
			if (typeof val === 'number') return val
			return parseInt(val, 10)
		})
		.refine((val) => !Number.isNaN(val) && val >= 30 && val <= 1095, {
			message: 'Expire period must be between 30 and 1095 days',
		}),
	certificateCourses: z
		.array(
			z.object({
				id: z.number(),
				courseName: z.string(),
			}),
		)
		.nonempty({ message: 'At least one course is required' }),
	certificateAssessments: z
		.array(
			z.object({
				id: z.number(),
				proficiencyAssessment: z.object({
					id: z.number(),
					name: z.string(),
				}),
			}),
		)
		.optional(),
})

function EditCertificate() {
	const history = useHistory()
	const { id } = useParams()

	const {
		data: courseData = [],
		isLoading: isLoadingCourses,
		fetchError: courseError,
	} = useAxiosFetch(`${BASE_URL}/${'courses'}`, 'token')

	const {
		data: proficiencyAssessmentData = [],
		isLoading: isLoadingProficiencyAssessments,
		fetchError: proficiencyAssessmentError,
	} = useAxiosFetch(`${BASE_URL}/proficiency-assessments`, 'token')

	const { certificateData, isLoading, error } =
		useCertificateDataFetcher<Certificate>(id, 'token')

	const [snackbarOpen, setSnackbarOpen] = useState(false)
	const [snackbarMessage, setSnackbarMessage] = useState('')
	const [snackbarSeverity, setSnackbarSeverity] =
		useState<SnackbarSeverity>('success')

	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false)
	const [updateLoading, setUpdateLoading] = useState(false)

	const [formData, setFormData] = useState<FormData | null>(null)
	const [originalFormData, setOriginalFormData] = useState<FormData | null>(
		null,
	)

	const defaultValues: FormData = {
		certificateName: certificateData?.certificateName ?? '',
		certificateCourses: certificateData?.certificateCourses ?? [],
		certificateAssessments: certificateData?.certificateAssessments ?? [],
		minimumMasteryScore: certificateData?.minimumMasteryScore ?? 0,
		expiryPeriod: certificateData?.expiryPeriod ?? 0,
	}

	const {
		register,
		handleSubmit,
		setValue,
		watch,
		control,
		formState: { errors },
	} = useForm<FormData>({
		resolver: zodResolver(certificateSchema),
		defaultValues,
		mode: 'onChange',
	})

	const selectedCourses = watch('certificateCourses')
	const selectedCertificateAssessments = watch('certificateAssessments')
	const certificateName = watch('certificateName')

	const handleRemoveCourse = (courseIdToRemove: number) => {
		const updatedCourses = selectedCourses.filter(
			(course) => course.id !== courseIdToRemove,
		)
		setValue('certificateCourses', updatedCourses)
	}

	const handleRemoveProficiencyAssessment = (assessmentIdToRemove: number) => {
		const updatedAssessments = selectedCertificateAssessments.filter(
			(assessment) =>
				assessment.proficiencyAssessment.id !== assessmentIdToRemove,
		)
		setValue('certificateAssessments', updatedAssessments)
	}

	const navigateToCertificatesList = () => {
		history.push('/manage-certificates')
	}

	const updateCertificate = useCallback(
		async (certificateEditData: FormData) => {
			try {
				const postData = {
					certificateName: certificateEditData.certificateName,
					minimumMasteryScore: certificateEditData.minimumMasteryScore,
					expiryPeriod: certificateEditData.expiryPeriod,
					certificateOwner: 1,
					certificateIssuer: 'Blue Spark Energy',
					certificateCourses: certificateEditData.certificateCourses.map(
						({ id: courseId }) => ({ courseId }),
					),
					proficiencyAssessmentIds:
						certificateEditData.certificateAssessments.map(
							(assessment) => assessment.proficiencyAssessment.id,
						),
				}

				const res = await axios.patch(
					`${BASE_URL}/certificates/${id}/certificate`,
					postData,
					{
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${localStorage.getItem('token')}`,
						},
					},
				)
				setSnackbarMessage('Certificate updated successfully!')
				setSnackbarSeverity('success')
				setSnackbarOpen(true)
				console.log(res)
				setTimeout(() => history.push('/manage-certificates'), 2000)
			} catch (err) {
				console.error(err)
				let errorMessage = 'Error updating certificate.'
				if (axios.isAxiosError(err) && err.response) {
					const serverMessage = err.response.data?.message || ''
					errorMessage = serverMessage || errorMessage
				}
				console.log('Error Message:::>>>', errorMessage)
				setSnackbarMessage(errorMessage)
				setSnackbarSeverity('error')
				setSnackbarOpen(true)
				throw err
			}
		},
		[history, id],
	)

	const onSubmit = async (data: FormData) => {
		setFormData(data)
		setConfirmDialogOpen(true)
	}

	const handleEditCertificate = async () => {
		if (certificateData && formData) {
			setUpdateLoading(true)
			try {
				await updateCertificate(formData)
				setConfirmDialogOpen(false)
			} catch (err) {
				console.error('Error updating certificate:', err)
			} finally {
				setUpdateLoading(false)
			}
		}
	}

	const handleSnackbarClose = (
		event: React.SyntheticEvent<Element, Event>,
		reason?: string,
	) => {
		if (reason === 'clickaway') {
			return
		}
		setSnackbarOpen(false)
	}

	useEffect(() => {
		if (
			certificateData &&
			courseData.length &&
			proficiencyAssessmentData.length
		) {
			console.log('certificateData>>>>>>', certificateData)

			const typedCourseData: Course[] = courseData as Course[]
			console.log('Typed Course Data:', typedCourseData)

			// Mapping certificate courses to the full course details from courseData
			const mappedCourses = certificateData.certificateCourses
				.map((certificateCourse) =>
					typedCourseData.find(
						(course) => course.courseName === certificateCourse.courseName,
					),
				)
				.filter((course): course is Course => course !== undefined)

			console.log('Mapped Courses:', mappedCourses)

			const mappedAssessments = certificateData.certificateAssessments

			console.log('Mapped Assessments:', mappedAssessments)

			setValue('certificateName', certificateData.certificateName)
			setValue('certificateCourses', mappedCourses)
			setValue('certificateAssessments', mappedAssessments)
			setValue('minimumMasteryScore', certificateData.minimumMasteryScore)
			setValue('expiryPeriod', certificateData.expiryPeriod)

			const initialFormData = {
				certificateName: certificateData.certificateName,
				certificateCourses: mappedCourses,
				certificateAssessments: mappedAssessments,
				minimumMasteryScore: certificateData.minimumMasteryScore,
				expiryPeriod: certificateData.expiryPeriod,
			}
			setOriginalFormData(initialFormData)
		}
	}, [certificateData, courseData, proficiencyAssessmentData, setValue])

	const watchedFormData = watch()

	const hasFormChanged = useMemo(() => {
		if (!originalFormData) return false

		return JSON.stringify(watchedFormData) !== JSON.stringify(originalFormData)
	}, [watchedFormData, originalFormData])

	if (isLoading || isLoadingCourses || isLoadingProficiencyAssessments) {
		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<CircularProgress size={80} />
			</div>
		)
	}

	if (error || courseError || proficiencyAssessmentError) {
		return (
			<MainCard title="Edit Certificate">
				<Alert severity="error">
					Error loading data:{' '}
					{error || courseError || proficiencyAssessmentError}
				</Alert>
			</MainCard>
		)
	}

	if (!certificateData || !courseData || !proficiencyAssessmentData) {
		return (
			<MainCard title="Edit Certificate">
				<Alert severity="info">No data available to display.</Alert>
			</MainCard>
		)
	}

	return (
		<MainCard
			title="Edit Certificate"
			secondary={
				<Button
					variant="contained"
					className="link_button"
					size="medium"
					onClick={navigateToCertificatesList}
					disabled={updateLoading}
				>
					Return to Certificates List
				</Button>
			}
		>
			<Typography variant="h5" gutterBottom sx={{ mb: 0.5 }} />

			<form onSubmit={handleSubmit(onSubmit)}>
				<Grid container spacing={3}>
					<Grid item xs={12} sm={6}>
						<TextField
							{...register('certificateName')}
							error={Boolean(errors.certificateName)}
							helperText={errors.certificateName?.message}
							required
							id="name"
							label="Certificate name"
							fullWidth
							disabled={updateLoading}
						/>
					</Grid>
					<Grid item xs={12} sm={6} />

					<Grid item xs={12} sm={3}>
						<TextField
							{...register('minimumMasteryScore')}
							error={Boolean(errors.minimumMasteryScore)}
							helperText={errors.minimumMasteryScore?.message}
							required
							label="Minimum Mastery Score"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={3}>
						<TextField
							{...register('expiryPeriod')}
							error={Boolean(errors.expiryPeriod)}
							helperText={errors.expiryPeriod?.message}
							required
							label="Expire Period"
							fullWidth
						/>
					</Grid>
					<Grid item xs={12} sm={6} />
					<Grid item xs={12} sm={6}>
						<Controller
							name="certificateCourses"
							control={control}
							defaultValue={[]}
							render={({ field: { onChange, value, ref } }) => (
								<TextField
									select
									SelectProps={{ multiple: true }}
									label="Select the Courses here"
									error={Boolean(errors.certificateCourses)}
									helperText={errors.certificateCourses?.message}
									fullWidth
									onChange={(e) => {
										const selectedIds = e.target.value as unknown as string[]
										const selectedCourseObjects = selectedIds
											.map((selectedId) =>
												(courseData as Course[]).find(
													(course) => course.id.toString() === selectedId,
												),
											)
											.filter(
												(course): course is Course => course !== undefined,
											)
										onChange(selectedCourseObjects)
									}}
									value={
										value
											? (value as Course[]).map((course) =>
													course.id.toString(),
												)
											: []
									}
									inputRef={ref}
								>
									{courseData.map((course: Course) => (
										<MenuItem key={course.id} value={course.id.toString()}>
											{course.courseName}
										</MenuItem>
									))}
								</TextField>
							)}
						/>

						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
							{selectedCourses.map((course: Course) => (
								<LabelTag
									key={course.id}
									isSelectable
									onClose={() => handleRemoveCourse(course.id)}
									identifier={course.courseName}
								>
									{course.courseName}
								</LabelTag>
							))}
						</Box>
					</Grid>
					<Grid item xs={12} sm={6} />
					<Grid item xs={12} sm={6}>
						<Controller
							name="certificateAssessments"
							control={control}
							defaultValue={[]}
							render={({ field: { onChange, value, ref } }) => (
								<TextField
									select
									SelectProps={{ multiple: true }}
									label="Select Proficiency Assessments"
									error={Boolean(errors.certificateAssessments)}
									helperText={errors.certificateAssessments?.message}
									fullWidth
									onChange={(e) => {
										const selectedIds = e.target.value as unknown as string[]
										const selectedAssessmentObjects = selectedIds
											.map((selectedId) =>
												proficiencyAssessmentData.find(
													(assessment: ProficiencyAssessment) =>
														assessment.id.toString() === selectedId,
												),
											)
											.filter(
												(assessment): assessment is ProficiencyAssessment =>
													assessment !== undefined,
											)
											.map((assessment) => ({
												id: 0,
												proficiencyAssessment: assessment,
												proficiencyAssessmentId: assessment.id,
											}))
										onChange(selectedAssessmentObjects)
									}}
									value={
										value
											? value.map((assessment) =>
													assessment.proficiencyAssessment.id.toString(),
												)
											: []
									}
									inputRef={ref}
								>
									{proficiencyAssessmentData.map(
										(assessment: ProficiencyAssessment) => (
											<MenuItem
												key={assessment.id}
												value={assessment.id.toString()}
											>
												{assessment.name}
											</MenuItem>
										),
									)}
								</TextField>
							)}
						/>
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mt: 2 }}>
							{selectedCertificateAssessments.map(
								(assessment: CertificateAssessment) => (
									<LabelTag
										key={assessment.proficiencyAssessment.id}
										isSelectable
										onClose={() =>
											handleRemoveProficiencyAssessment(
												assessment.proficiencyAssessment.id,
											)
										}
										identifier={assessment.proficiencyAssessment.name}
									>
										{assessment.proficiencyAssessment.name}
									</LabelTag>
								),
							)}
						</Box>
					</Grid>
				</Grid>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						mt: 3,
						height: '48px',
					}}
				>
					<Button
						variant="contained"
						type="submit"
						sx={{
							backgroundColor:
								updateLoading || !hasFormChanged ? '#E0E0E0' : '#B29962',
							color: updateLoading || !hasFormChanged ? '#A0A0A0' : '#FFFFFF',
							'&:hover': {
								backgroundColor:
									updateLoading || !hasFormChanged ? '#E0E0E0' : '#B29962',
							},
							'&:disabled': {
								backgroundColor: '#E0E0E0',
								color: '#A0A0A0',
							},
						}}
						disabled={updateLoading || !hasFormChanged}
					>
						{updateLoading ? (
							<>
								<CircularProgress size={24} color="inherit" />
								<span style={{ marginLeft: '10px' }}>Updating...</span>
							</>
						) : (
							'Update Certificate'
						)}
					</Button>
				</Box>
			</form>
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={2000}
				onClose={handleSnackbarClose}
			>
				<MuiAlert
					elevation={6}
					variant="filled"
					onClose={handleSnackbarClose}
					severity={snackbarSeverity}
					sx={{
						color: snackbarSeverity === 'success' ? 'white' : '',
					}}
				>
					{snackbarMessage}
				</MuiAlert>
			</Snackbar>
			<Dialog
				open={confirmDialogOpen}
				onClose={() => {
					if (!updateLoading) setConfirmDialogOpen(false)
				}}
				aria-labelledby="confirm-dialog-title"
				aria-describedby="confirm-dialog-description"
			>
				{updateLoading ? (
					<DialogContent style={{ textAlign: 'center' }}>
						<CircularProgress size={80} />
					</DialogContent>
				) : (
					<>
						<DialogTitle id="confirm-dialog-title">
							Confirm Certificate Update
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="confirm-dialog-description">
								Please confirm the updates to the certificate named{' '}
								<Typography
									variant="body2"
									fontWeight="bold"
									component="span"
									color="primary"
								>
									{certificateName}?
								</Typography>
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								variant="outlined"
								disabled={updateLoading}
								onClick={() => setConfirmDialogOpen(false)}
							>
								Cancel
							</Button>
							<Button
								variant="contained"
								color="primary"
								sx={{
									color: '#FFFFFF',
									'&:hover': {
										backgroundColor: '#00aeef',
									},
								}}
								disabled={updateLoading}
								onClick={handleEditCertificate}
								autoFocus
							>
								Confirm
							</Button>
						</DialogActions>
					</>
				)}
			</Dialog>
		</MainCard>
	)
}

export default EditCertificate
