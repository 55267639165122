import {
	IconBrandChrome,
	IconHelp,
	IconSitemap,
	IconUserPlus,
	IconUserCheck,
	IconUserExclamation,
	IconCertificate,
	IconClipboard, // Add this import
} from '@tabler/icons'

import { MenuItem } from '../layout/MainLayout/Sidebar/MenuList/types'

const icons = {
	IconBrandChrome,
	IconHelp,
	IconSitemap,
	IconUserPlus,
	IconCertificate,
	IconUserExclamation,
	IconUserCheck,
	IconClipboard, // Add this icon
}

export default function getUserMenu(): MenuItem {
	return {
		id: 'user',
		title: 'Main',
		type: 'group',
		children: [
			{
				id: 'user-dashboard',
				title: 'User Dashboard',
				type: 'item',
				url: '/dashboard',
				icon: icons.IconSitemap,
				breadcrumbs: false,
			},
			{
				id: 'profile',
				title: 'Profile',
				type: 'item',
				url: '/profile',
				icon: icons.IconUserPlus,
				breadcrumbs: false,
			},
			{
				id: 'certificates',
				title: 'Certificates',
				type: 'item',
				url: '/certificates',
				icon: icons.IconCertificate,
				breadcrumbs: false,
			},
			{
				id: 'evaluations',
				title: 'Evaluations',
				type: 'item',
				url: '/evaluations',
				icon: icons.IconClipboard,
				breadcrumbs: false,
			},
		],
	}
}
