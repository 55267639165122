import React, { lazy } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'

import AuthGuard from '../utils/route-guard/AuthGuard'
import Loadable from '../ui-component/Loadable'
// project imports
import MainLayout from '../layout/MainLayout'
import EditCertificate from '../views/certificate-management/edit-certificate'
// dashboard routing
const DashboardDefault = Loadable(
	lazy(() => import('../views/dashboard/Default')),
)

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/categories')))
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')))
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')))
const UtilsMaterialIcons = Loadable(
	lazy(() => import('../views/utilities/MaterialIcons')),
)
const UtilsTablerIcons = Loadable(
	lazy(() => import('../views/utilities/TablerIcons')),
)
const CreateNewProduct = Loadable(
	lazy(() => import('../views/categories/Newcategory')),
)

// sample page routing
const SamplePage = Loadable(lazy(() => import('../views/sample-page')))
const Profile = Loadable(lazy(() => import('../views/profile')))
const Certificates = Loadable(lazy(() => import('../views/certificates')))
const Evaluations = Loadable(lazy(() => import('../views/evaluations')))
const Dashboard = Loadable(lazy(() => import('../views/user-dashboard')))
const Supervisor = Loadable(lazy(() => import('../views/supervisor')))
const NewUser = Loadable(lazy(() => import('../views/new-user')))
const Mastery = Loadable(lazy(() => import('../views/mastery')))
const Admin = Loadable(lazy(() => import('../views/admin')))
const CompaniesCreate = Loadable(
	lazy(() => import('../views/companies-create')),
)
const CompaniesEdit = Loadable(lazy(() => import('../views/companies-edit')))
const AssignUser = Loadable(lazy(() => import('../views/assign-user')))
const UserList = Loadable(lazy(() => import('../views/user-lists')))

const ManageCertificates = Loadable(
	lazy(() => import('../views/certificate-management/manage-certificates')),
)
const CreateCertificate = Loadable(
	lazy(() => import('../views/certificate-management/create-certificate')),
)
const ManageUserCertificate = Loadable(
	lazy(() => import('../views/certificate-management/manage-user-certificate')),
)

const UserEdit = Loadable(lazy(() => import('../views/user-list-edit')))
const UserStatusReport = Loadable(
	lazy(() => import('../views/user-dashboard/status-report')),
)
const UnassignedUsers = Loadable(
	lazy(() => import('../views/user-lists/unassignedUsers')),
)
const AdminCompanyReport = Loadable(
	lazy(() => import('../views/mastery/adminCompanyReport')),
)
const AdminUserMastery = Loadable(
	lazy(() => import('../views/mastery/adminMastery')),
)
const SupervisorsUserMastery = Loadable(
	lazy(() => import('../views/mastery/supervisorMastery')),
)
const SupervisorUsers = Loadable(
	lazy(() => import('../views/supervisor/users')),
)

const ManageProficiencyAssessments = Loadable(
	lazy(
		() =>
			import('../views/proficiency-assessment/manage-proficiency-assessments'),
	),
)
const CreateProficiencyAssessment = Loadable(
	lazy(
		() =>
			import('../views/proficiency-assessment/create-proficiency-assessment'),
	),
)
const EditProficiencyAssessment = Loadable(
	lazy(
		() => import('../views/proficiency-assessment/edit-proficiency-assessment'),
	),
)
const AssignEvaluatorToProficiencyAssessment = Loadable(
	lazy(
		() =>
			import(
				'../views/proficiency-assessment/assign-evaluator-to-proficiency-assessment'
			),
	),
)

function MainRoutes() {
	const location = useLocation()

	return (
		<Route
			path={[
				'/dashboard/default',
				'/utils/util-typography',
				'/utils/util-color',
				'/utils/util-shadow',
				'/icons/tabler-icons',
				'/icons/material-icons',
				'/category/createnew',
				'/sample-page',
				'/profile',
				'/certificates',
				'/evaluations',
				'/dashboard',
				'/supervisor',
				'/new-user',
				'/mastery',
				'/admin',
				'/create-company',
				'/edit-company/:id',
				'/assign-user',
				'/user-list',
				'/manage-certificates',
				'/create-certificate',
				'/edit-certificate/:id',
				'/manage-user-certificate/:id',
				'/user-edit/:id',
				'/user-status-report',
				'/unassigned-users',
				'/admin-company-report',
				'/admin-mastery',
				'/supervisor-mastery',
				'/supervisor-users',
				'/manage-proficiency-assessments',
				'/create-proficiency-assessment',
				'/edit-proficiency-assessment/:id',
				'/assign-evaluator-to-proficiency-assessment/:id',
			]}
		>
			<MainLayout>
				<Switch location={location} key={location.pathname}>
					<AuthGuard>
						<Route path="/dashboard/default" component={DashboardDefault} />
						<Route path="/utils/util-typography" component={UtilsTypography} />
						<Route path="/utils/util-color" component={UtilsColor} />
						<Route path="/utils/util-shadow" component={UtilsShadow} />
						<Route path="/icons/tabler-icons" component={UtilsTablerIcons} />
						<Route
							path="/icons/material-icons"
							component={UtilsMaterialIcons}
						/>
						<Route path="/category/createnew" component={CreateNewProduct} />
						<Route path="/sample-page" component={SamplePage} />
						<Route path="/profile" component={Profile} />
						<Route path="/certificates" component={Certificates} />
						<Route path="/evaluations" component={Evaluations} />
						<Route path="/dashboard" component={Dashboard} />
						<Route path="/supervisor" component={Supervisor} />
						<Route path="/new-user" component={NewUser} />
						<Route path="/mastery" component={Mastery} />
						<Route path="/admin" component={Admin} />
						<Route path="/create-company" component={CompaniesCreate} />
						<Route path="/edit-company/:id" component={CompaniesEdit} />
						<Route path="/assign-user" component={AssignUser} />
						<Route path="/user-list" component={UserList} />
						<Route path="/manage-certificates" component={ManageCertificates} />
						<Route
							path="/manage-user-certificate/:id"
							component={ManageUserCertificate}
						/>
						<Route path="/create-certificate" component={CreateCertificate} />
						<Route path="/edit-certificate/:id" component={EditCertificate} />
						<Route path="/user-edit/:id" component={UserEdit} />
						<Route path="/user-status-report" component={UserStatusReport} />
						<Route path="/unassigned-users" component={UnassignedUsers} />
						<Route
							path="/admin-company-report"
							component={AdminCompanyReport}
						/>
						<Route path="/admin-mastery" component={AdminUserMastery} />
						<Route
							path="/supervisor-mastery"
							component={SupervisorsUserMastery}
						/>
						<Route path="/supervisor-users" component={SupervisorUsers} />
						<Route
							path="/manage-proficiency-assessments"
							component={ManageProficiencyAssessments}
						/>
						<Route
							path="/create-proficiency-assessment"
							component={CreateProficiencyAssessment}
						/>
						<Route
							path="/edit-proficiency-assessment/:id"
							component={EditProficiencyAssessment}
						/>
						<Route
							path="/assign-evaluator-to-proficiency-assessment/:id"
							component={AssignEvaluatorToProficiencyAssessment}
						/>
					</AuthGuard>
				</Switch>
			</MainLayout>
		</Route>
	)
}

export default MainRoutes
