import {
	IconDashboard,
	IconDeviceAnalytics,
	IconBrandChrome,
	IconHelp,
	IconSitemap,
	IconUserPlus,
	IconUserCheck,
	IconUserExclamation,
	IconCertificate,
	IconUser,
	IconClipboard,
} from '@tabler/icons'
import { MenuItem } from '../layout/MainLayout/Sidebar/MenuList/types'

const icons = {
	IconDashboard,
	IconDeviceAnalytics,
	IconBrandChrome,
	IconHelp,
	IconSitemap,
	IconUserPlus,
	IconCertificate,
	IconUserExclamation,
	IconUserCheck,
	IconUser,
	IconClipboard,
}

export default function getAdminMenu(): MenuItem {
	return {
		id: 'admin',
		title: 'Dashboard',
		type: 'group',
		children: [
			{
				id: 'user-dashboard',
				title: 'User Dashboard',
				type: 'item',
				url: '/dashboard',
				icon: icons.IconSitemap,
				breadcrumbs: false,
			},
			{
				id: 'profile',
				title: 'Profile',
				type: 'item',
				url: '/profile',
				icon: icons.IconUserPlus,
				breadcrumbs: false,
			},
			{
				id: 'certificates',
				title: 'Certificates',
				type: 'item',
				url: '/certificates',
				icon: icons.IconCertificate,
				breadcrumbs: false,
			},
			{
				id: 'evaluations',
				title: 'Evaluations',
				type: 'item',
				url: '/evaluations',
				icon: icons.IconClipboard,
				breadcrumbs: false,
			},
			{
				id: 'new-user',
				title: 'New User',
				type: 'item',
				url: '/new-user',
				icon: icons.IconUserCheck,
				breadcrumbs: false,
			},
			{
				id: 'mastery',
				title: 'Status',
				type: 'item',
				url: '/mastery',
				icon: icons.IconHelp,
				breadcrumbs: false,
			},
			{
				id: 'admin',
				title: 'Admin Control',
				type: 'item',
				url: '/admin',
				icon: icons.IconUser,
				breadcrumbs: false,
			},
		],
	}
}
